// Main entry point to the app.
// Do not put non-trivial kode in here, nor import from here.

// ██████  ██      ██ ████████ ███████     ██████   ██████
// ██   ██ ██      ██    ██       ███     ██       ██
// ██████  ██      ██    ██      ███      ██   ███ ██   ███
// ██   ██ ██      ██    ██     ███       ██    ██ ██    ██
// ██████  ███████ ██    ██    ███████ ██  ██████   ██████

// X======================================================X
// |                                                      |
// |  HELLO, SOURCE KODE EXPLORER!                        |
// |                                                      |
// |  We publish our sourcemaps on production to aid in   |
// |  debugging, and also because users send screenshots  |
// |  of errors, so we want that info to be useful.       |
// |                                                      |
// |  See below for why we are PRO SOURCEMAP.             |
// |                                                      |
// X======================================================X

// https://css-tricks.com/should-i-use-source-maps-in-production/

// Since import ordering in here is quite sensitive, need to disable this rule.
/* eslint-disable simple-import-sort/imports */

// import "@/app/redirect-domain.mjs";
import "@/util/frame-buster.mjs";
import "@/app/konami-kode.mjs";
import "@/util/obscure-globals.mjs";
import "@/util/init-service-worker.mjs";
import diff from "@/util/diff.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import clone from "@/util/clone.mjs";
import createQueue from "@/util/create-queue.mjs";
import deepEqual from "@/util/deep-equal.mjs";
import isCyclic from "@/util/is-cyclic.mjs";
import debounce from "@/util/debounce.mjs";
import lruObject from "@/util/lru-object.mjs";
import mapOriginalRefs, {
  forceOverwriteIterable,
} from "@/util/map-original-refs.mjs";
import optionalMerge from "@/util/optional-merge.mjs";
import * as stateCleanup from "@/util/state-cleanup.mjs";
import * as symbolName from "@/util/symbol-name.mjs";
import * as dataModel from "@/__main__/data-model.mjs";
import mainRefs from "@/__main__/refs.mjs";
import dbObj, {
  __PRIVATE_DB_SYMBOL_FOR_INTERNAL_USE_NOT_APPLICATION_CODE,
  wrapDBMethod,
} from "@/__main__/db.mjs";
import * as featureFlags from "@/__main__/feature-flags.mjs";
import * as getData from "@/__main__/get-data.mjs";
import * as router from "@/__main__/router.mjs";
import { createAppInstance, mountApp } from "@/__main__/App.jsx";
import { IS_APP, IS_NODE, isInitial } from "@/__main__/constants.mjs";
import * as IPCCore from "@/__main__/ipc-core.mjs";
import * as appState from "@/__main__/app-state.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import routes from "@/routes/routes.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { CHAMPION_TABS } from "@/game-lol/routes.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { tftChampTabs } from "@/game-tft/routes.mjs";
import { setPatches } from "@/game-tft/constants.mjs";
import gamesList from "@/app/games.mjs";

// Used for integration testing.
export { IPCCore };
export { mountApp, unmountApp } from "@/__main__/App.jsx";
export { default as eventBus } from "@/app/app-event-bus.mjs";
export { default as globals } from "@/util/global-whitelist.mjs";

// Avoid eagerly loading.
export const getIPCGameIntegrations = () =>
  import("@/__main__/ipc-game-integrations.mjs");

// Used for unit testing.
const db = dbObj[__PRIVATE_DB_SYMBOL_FOR_INTERNAL_USE_NOT_APPLICATION_CODE];
export { default as digPath } from "@/util/dig-path.mjs";
export {
  appState,
  dataModel,
  db,
  featureFlags,
  getData,
  mainRefs,
  router, // Used internally as well.
  setVolatileKV,
  wrapDBMethod,
};

// Unit testing utils.
export {
  clone,
  createQueue,
  debounce,
  deepEqual,
  diff,
  forceOverwriteIterable,
  isCyclic,
  isInitial,
  lruObject,
  mapOriginalRefs,
  optionalMerge,
  routes, // Used for acceptance testing, too.
  stateCleanup,
  symbolName,
};

// Used in sitemap generation.
export {
  appURLs,
  CHAMPION_TABS,
  gamesList,
  lolRefs,
  tftChampTabs,
  setPatches as tftPatchToSet,
};

// Internal dependencies, also used for SSR.
export const { EVENT_CHANGE_ROUTE, EVENT_LOADED_DATA, extractMeta, setRoute } =
  router;

// Used for SSR.
export { createAppInstance };
export const { featurePromises, waitForFeatureFlags } = featureFlags;
export { i18n } from "@/i18n/i18n.mjs";
export { extractCss } from "goober";
export { default as React } from "react";
// Used for SSR, taking advantage of kode splitting here.
export const getReactDOMServer = () => import("react-dom/server");

// Render the client-side app.
if (!IS_NODE) {
  mainRefs.mountPromise = mountApp(createAppInstance());
}

// Memory kleanup.
if (IS_APP) {
  lazyFn(() => import("@/util/heap-checker.mjs"))();
}
